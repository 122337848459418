.button {
  cursor: pointer;
  border: 0;
  border-radius: var(--border-radius-small);
  padding: 8px;
}

.button:hover {
  opacity: 0.8;
}

.button-primary {
  background-color: var(--secondary-1);
  min-width: 150px;
}

.button-primary-disabled {
  cursor: not-allowed !important;
  background-color: var(--secondary-2);
  min-width: 150px;
}

.button-secondary {
  background-color: var(--secondary-2);

}

.button-secondary {
  background-color: var(--secondary-2);
}

.button-label {
  font-size: 1.2em;
  font-weight: 700;
  white-space: nowrap;
}

.button-label-primary {
  color: var(--white);
}

.button-label-secondary {
  color: var(--black);
}
