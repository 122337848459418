.bg-primary {
    background-color: rgb(var(--primary-rgb), 0.15);
}

.bg-secondary-1 {
    background-color: rgb(var(--secondary-1-rgb), 0.15);
}

.bg-secondary-2 {
    background-color: rgb(var(--secondary-2-rgb), 0.2);
}

.bg-tertiary-1 {
    background-color: var(--tertiary-1-rgb, 0.6);
}

.bg-tertiary-2 {
    background-color: var(--tertiary-2-rgb, 0.6);
}

.bg-tertiary-3 {
    background-color: var(--tertiary-3-rgb, 0.6);
}

.bg-black-10 {
    background-color: rgb(var(--black-rgb), 0.1);
}