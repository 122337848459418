/* tabs-headers */
.tabs-headers {
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--secondary-2);
  border-bottom: 1px solid #e5e5e5;
  padding: 6px;
  border-radius: var(--border-radius-medium);
}

.tabs-header-item {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1 1 auto;
  height: 100%;
  padding: 6px;
  border-radius: var(--border-radius-medium);
  cursor: pointer;
}

.tabs-body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--white);
  padding: 2px;
  margin-top: 12px;
  /* border radius for all side except top */
  border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
}